(function() {
    const setFavicon = e => {
        let icon = $("link[rel*='icon']");

        if (e.matches) {
            icon.attr("href", window.iconDark);
        } else {
            icon.attr("href", window.iconLight);
        }
    }

    const mode = window.matchMedia("(prefers-color-scheme: dark)");
    mode.addEventListener('change', setFavicon);
    setFavicon(mode);

    let lastSection = null;

    $(".main").onepage_scroll({
        sectionContainer: "section.page",
        pagination: true,
        responsive: true,
        loop: false,
        keyboard: true,
        beforeMove: function(index, next_el) {
            var element = $('section[data-index='+index+']');
            
            $('body, div.overlay, div.logo, div.page_information').css('background-color', element.data('color'));
            $('section[data-index='+index+'] div.page_container').removeClass('fadeOutDown');
            $('section[data-index='+index+'] div.page_container').addClass('fadeInUp');
            
            if (lastSection !== null) {
                var idx = $(lastSection).data('index');
                $('section[data-index='+idx+'] div.page_container').removeClass('fadeInUp');
                $('section[data-index='+idx+'] div.page_container').addClass('fadeOutDown');            
            }
            
            lastSection = next_el;
        },
        afterMove: function(index, next_el) {

        }
    });
    
    $('div.page_information').on('mousewheel DOMMouseScroll touchmove touchstart swipeDown swipeUp', function(e){
        e.stopPropagation();
    });
    
    $('#home').on('click', function(e){
        e.preventDefault();
        $(".main").moveTo(1);
    })
})();
